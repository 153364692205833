<mat-dialog-content>
    <h2 matDialogTitle>Contact Details</h2>
    <div class="row" *ngIf="!formGroup; else form">
        <div class="d-flex flex-column align-items-center">
            <mat-spinner>{{runInit() | async}}</mat-spinner>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="undefined" class="bcb-btn btn-small danger">Cancel</button>
    <button mat-flat-button color="primary"
            [disabled]="formGroup?.invalid && !systemUser$.value"
            (click)="submit()">Save
    </button>
</mat-dialog-actions>

<ng-template #form>
    <form [formGroup]='formGroup'>
        <div class="row">
            <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                   [currentStep]="currentStep.key"
                                   [entity]="entity"
                                   [formGroup]="formGroup"
                                   [questions]="questions"
                                   [questionsConfig]="questionsConfig"
                                   [visibleControls]="visibleControls"
            ></bcb-component-wrapper>

            <ng-container *ngIf='showJustification'>
                <mat-divider style='margin: 25px 0'></mat-divider>
                <div class='col-12 lbl-stacked'>
                    <mat-label>Justification</mat-label>
                    <mat-form-field appearance='outline'>
                            <textarea matInput [formControl]='justificationFormControl'
                                      (input)='formGroup.markAsDirty()' rows='4'></textarea>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
    </form>
</ng-template>