import { Component, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { Entity } from '../../../../core/models/entity.model';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { first } from 'rxjs/operators';
import { OnboardingStateModel } from '../../../../core/store/onboarding/onboarding.state';
import { Descriptor } from '../../../../core/models/descriptor.interface';
import { StaticData } from '../../../../core/constants/static.const';
import { environment } from '../../../../../environments/environment';
import { StepModel } from '../../../stepper/models/step.model';

@Component({
    selector: 'bcb-source-of-funds',
    templateUrl: './source-of-funds.component.html',
    styleUrls: ['./source-of-funds.component.scss']
})
export class SourceOfFundsComponent implements OnInit {

    entity?: Entity;

    sourceOfFunds: Array<Descriptor> = StaticData.sourceOfFundsOptions;
    sourceOfFundsDocuments: Array<{ filename: string, type: string, document?: string, content_type?: string }> = []
    progress: { [key: string]: number } = {};
    disabled: boolean = false

    constructor(private readonly stepperQueries: StepperQueries,
                private readonly entityQueries: EntityQueries,
                private readonly onboardingQueries: OnboardingQueries,
                private readonly http: HttpService) {
    }

    ngOnInit(): void {
        this.entityQueries.getState().pipe(first()).subscribe((entity) => this.entity = entity as Entity)
        this.onboardingQueries.getState().pipe(first()).subscribe((onboarding: OnboardingStateModel) => {
            this.sourceOfFundsDocuments = onboarding.uploaded_documents?.filter((item: any) => item.type.includes('Source of funds:')) ?? [];
        });
        this.onAdd();

        this.stepperQueries
            .getStep()
            .pipe(first())
            .subscribe((currentStepData: StepModel | undefined) => {
                if(currentStepData?.metadata?.disable ?? false) {
                    this.disabled = true
                }
            });
    }

    onAdd(): void {
        this.sourceOfFundsDocuments.push({type: '', filename: ''});
    }

    fileChange({files}: any, index?: number): void {
        const reader = new FileReader();
        if (files && files.length > 0) {
            const file = files[0];
            reader.readAsDataURL(file);

            reader.onload = () => {
                // @ts-ignore
                this.sourceOfFundsDocuments[index] = {
                    // @ts-ignore
                    ...this.sourceOfFundsDocuments[index],
                    filename: files[0].name,
                    document: String(reader.result),
                    content_type: files[0].type
                }
            }
        }
    }

    typeChange(value: string, index?: number): void {
        // @ts-ignore
        this.sourceOfFundsDocuments[index] = {
            // @ts-ignore
            ...this.sourceOfFundsDocuments[index],
            type: value
        }
    }

    onNextClick(): void {
        const data: Array<any> = this.sourceOfFundsDocuments.filter((doc: any) => !!doc.document);
        if (data.length) {
            this.http.uploadDocuments({
                documents: data,
                entity_id: this.entity?.id,
                step: 'source_of_funds'
            }).subscribe(() => {
                this.entityQueries.loadState();
                this.stepperQueries.navigateStep('next');
            })
        } else {
            this.stepperQueries.navigateStep('next');
        }
    }

    onPreviousClick(): void {
        const data: Array<any> = this.sourceOfFundsDocuments.filter((doc: any) => !!doc.document);
        if (data.length) {
            this.http.uploadDocuments({
                documents: data,
                entity_id: this.entity?.id,
                step: 'source_of_funds'
            }).subscribe(() => {
                this.entityQueries.loadState();
                this.stepperQueries.navigateStep('prev');
            })
        } else {
            this.stepperQueries.navigateStep('prev');
        }
    }

    private upload(data: CreateDocument): void {
        this.http.uploadDocuments({
            documents: [data],
            entity_id: this.entity?.id,
            step: 'source_of_funds'
        }).subscribe()
    }

    private constructDocument(file: File, readerResult: string | ArrayBuffer | null, documentType: string): CreateDocument {
        return {
            filename: file.name,
            content_type: file.type,
            document: String(readerResult),
            type: documentType
        }
    }

}

interface CreateDocument {
    filename: string;
    content_type: string;
    document: string;
    type: string;
}
