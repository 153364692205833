import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigService {
  private config!: EnvironmentConfig

  constructor() {
    if(Object.isFrozen(environment)) {
      this.config = environment
      console.info('config initialized successfully')
    }
  }

  static async initialize() {
    return await new ConfigService().Init()
  }

  async Init() {
    if(!this.config) {
      await this.loadConfig();
      console.info(`config loaded successfully`);
    }

    if(Object.isFrozen(environment)) {
      console.info('environment already initialized, skipping config load')
      return
    }
    this.setEnvironment()

  }

  private async loadConfig() {
    try {
      this.config = await this.fetchConfig('./assets/config/config.json')
    }
    catch (error) {
      console.error('Error loading config', error)
    }
  }

  private setEnvironment(): void {
    try {
      Object.keys(this.config).forEach(key => {
        environment[key] = this.config[key];
      })
      Object.freeze(environment)
      console.info('environment initialized')
    } catch (e) {
      console.error('Error initializing environment', e)
    }
  }

  private async fetchConfig(path: string): Promise<EnvironmentConfig> {
    const res = await fetch(path)
    return res.json()
  }
}

export interface EnvironmentConfig {
  appVersion?: string
  production: boolean
  apiUrl?: string
  onboardingApiUrl?: string
  authTokenKey?: string
  sentryDSN?: string
  sentryEnabled?: boolean
  logRocketAppID: string
  maxFileUploadSizeBytes?: string
  [key: string]: any // allow for any other properties
}